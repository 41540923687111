<template>
  <div style="width: 100vw;">
    <div class="center-block-date">
      <Select v-model="queryModel.startDate" style="width:100px" size="small" @on-change="handleChangeDate">
        <Option v-for="(date,index) in startDateArray" :key="index" :value="date.key">{{date.value}}</Option>
      </Select>
      <span class="m-l-5 m-r-5">至</span>
      <Select v-model="queryModel.endDate" style="width:100px" size="small" @on-change="handleChangeDate">
        <Option v-for="(date,index) in endDateArray" :key="index" :value="date.key">{{date.value}}</Option>
      </Select>
    </div>

    <div class="kpi-center-block" style="left:76px;right:2px">
      <CenterLeft :style="{'background': waterImageUrl}" style="border-right: 2px solid #1a1a1a;" class="kpi-center-block-content"/>
      <CenterRight :style="{'background': waterImageUrl}" class="kpi-center-block-content"/>
    </div>
  </div>
</template>

<script>
import CenterLeft from '../centerComponents/Center_Left'
import CenterRight from '../centerComponents/Center_Right'

import { getStorage } from '@/utils/storage'
import { getUserWaterImage } from '@/api/dw/image'
import { getStartDateList, getEndDateList } from '@/api/dw/time'

export default {
  components: {
    CenterLeft, CenterRight
  },
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      loginCompanyId: this.$store.getters.token.userInfo.companyId,
      startDateArray: [],
      endDateArray: [],
      waterImageUrl: '',

      queryModel: {
        endDate: '',
        startDate: ''
      }
    }
  },
  created () {
    this.queryModel.startDate = this.searchSchedule.startDate
    this.queryModel.endDate = this.searchSchedule.endDate
    this.initPageData()
    this.loadWaterImage()
  },
  methods: {
    initPageData () {
      const postData = { publisherId: this.publisherId }
      getStartDateList(postData).then(res => {
        this.startDateArray = res
      })
      getEndDateList(postData).then(res => {
        this.endDateArray = res
      })
    },
    handleChangeDate () {
      const schedule = {
        startDate: this.queryModel.startDate,
        endDate: this.queryModel.endDate
      }

      this.$store.commit('set_situation_companyId', (this.loginCompanyId === this.publisherId) ? '0' : this.loginCompanyId)

      this.$store.commit('set_situation_searchSchedule', schedule)
    },
    loadWaterImage () { // 载入水印图片
      const param = {
        loginUserId: this.$store.getters.token.userInfo.userId,
        color: this.themeIsDark ? 'black' : 'white'
      }

      getUserWaterImage(param).then(res => {
        this.waterImageUrl = 'url(' + res[0].value + ') repeat'
      })
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    themeIsDark () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark'
    }
  }
}
</script>

<style scoped>
.kpi-center-block{
  bottom: 5px;
  position: absolute;
  top: 75px;
}
.kpi-center-block-content{
  width: 50%;
  display: inline-block;
  height: 100%;
}
</style>
