<template>
  <div>
    <Row class="text-center p-t-10">
      <i-col span="8">
        <p class="text-14">报备客户数</p>
        <b class="text-24">{{advertiserNumber}}</b>
      </i-col>
      <i-col span="8">
        <p class="text-14">签约客户数</p>
        <b class="text-24">{{signAdvertiserNumber}}</b>
      </i-col>
      <i-col span="8">
        <p class="text-14">新增客户数</p>
        <b class="text-24">{{addAdvertiserNumber}}</b>
      </i-col>
    </Row>

    <div style="width:100%;height:180px;" id="kpiCenterLeftReport"></div>
    <Row class="table-title p-l-10 p-r-10" :gutter="8" style="margin: 20px 10px 0 10px;">
      <i-col span="12">行业</i-col>
      <i-col span="6">客户数量</i-col>
      <i-col span="6" class="text-right">占比</i-col>
    </Row>
    <Row v-for="(item,index) in advertiserListByIndustry" :key="index" :gutter="8" class="p-t-5 p-l-20 p-r-20">
      <i-col span="12">{{item.name}}</i-col>
      <i-col span="6">{{item.number}}</i-col>
      <i-col span="6" class="text-right">{{item.rate}}%</i-col>
    </Row>
    <div v-show="showArray.length>currentPageSize" class="paging_style">
      <Page size="small" :total="showArray.length" :page-size="currentPageSize" :current="currentPageNumber" @on-change="handlePageChange"></Page>
    </div>
    <p v-if="advertiserListByIndustry.length===0" class="remark p-t-20 text-center">暂无数据</p>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { countHaveAdvertiserNumberGbIndustry, countHaveAdvertiserNumber, countUserSignAdvertiserNumber, countAddAdvertiserNumber } from '@/api/dw/kpi'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,

      advertiserListByIndustry: [],
      showArray: [],
      currentPageSize: 10,
      currentPageNumber: 1,
      leftDataSourcePie: null,

      advertiserNumber: 0,
      signAdvertiserNumber: 0,
      addAdvertiserNumber: 0
    }
  },
  mounted () {
    this.loadAdvertiseCount()
    this.loadAdvertiserNumberByDate()
  },
  methods: {
    loadAdvertiseCount () {
      const queryModel = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        industryGroupId: this.gbType !== '2' ? this.gbId : null,
        userId: this.gbType === '2' ? this.gbId : null
      }

      countHaveAdvertiserNumber(queryModel).then(res => {
        this.advertiserNumber = res[0].value
      })
      countHaveAdvertiserNumberGbIndustry(queryModel).then(res => {
        this.showArray = res
        this.handlePageChange(1)

        const reportData = res.map(element => {
          return {
            value: element.rate,
            name: element.name
          }
        })
        this.initLeftChart(reportData)
      })
    },
    loadAdvertiserNumberByDate () {
      const queryModel = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        industryGroupId: this.gbType !== '2' ? this.gbId : null,
        userId: this.gbType === '2' ? this.gbId : null
      }

      countUserSignAdvertiserNumber(queryModel).then(res => {
        this.signAdvertiserNumber = res[0].value
      })
      countAddAdvertiserNumber(queryModel).then(res => {
        this.addAdvertiserNumber = res[0].value
      })
    },
    handlePageChange (page) {
      this.currentPageNumber = page
      const newArray = []
      for (let index = this.currentPageSize * (page - 1); index < this.currentPageSize * page; index++) {
        if (index < this.showArray.length) {
          newArray.push(this.showArray[index])
        } else {
          break
        }
      }

      this.advertiserListByIndustry = newArray
    },
    initLeftChart (reportData) {
      if (this.leftDataSourcePie != null && this.leftDataSourcePie !== '' && this.leftDataSourcePie !== undefined) {
        this.leftDataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('kpiCenterLeftReport')) {
          this.leftDataSourcePie = echarts.init(document.getElementById('kpiCenterLeftReport'))
          const option = {
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                name: '行业报备客户分布',
                type: 'pie',
                radius: ['70%', '90%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: '15',
                    formatter: '{d}%\n{b}',
                    color: '#fff'
                  }
                },
                labelLine: {
                  show: false
                },
                data: reportData
              }
            ]
          }

          this.leftDataSourcePie.setOption(option, true)
          const that = this
          window.addEventListener('resize', function () {
            that.leftDataSourcePie.resize()
          })

          // 默认选中第一项
          let index = 0
          const _self = this
          _self.leftDataSourcePie.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: 0
          }) // 设置默认选中高亮部分
          _self.leftDataSourcePie.on('mouseover', function (e) {
            if (e.dataIndex !== index) {
              _self.leftDataSourcePie.dispatchAction({
                type: 'downplay',
                seriesIndex: 0,
                dataIndex: index
              })
            }
          })
          _self.leftDataSourcePie.on('mouseout', function (e) {
            index = e.dataIndex
            _self.leftDataSourcePie.dispatchAction({
              type: 'highlight',
              seriesIndex: 0,
              dataIndex: e.dataIndex
            })
          })
        }
      })
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    },
    gbId () {
      return this.$store.state.situationWeb.gbId
    },
    gbType () {
      return this.$store.state.situationWeb.gbType
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadAdvertiserNumberByDate()
      }
    },
    companyId () {
      this.loadAdvertiseCount()
      this.loadAdvertiserNumberByDate()
    },
    gbType () {
      this.loadAdvertiseCount()
      this.loadAdvertiserNumberByDate()
    },
    gbId () {
      this.loadAdvertiseCount()
      this.loadAdvertiserNumberByDate()
    }
  }
}
</script>
